import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTree } from '../../../molecules/TreeSteps/hook'
import ChoicesBase from '../../ChoicesBase'
import { BathroomLayout } from '../../BathroomLayout'
import {
    CommerceSpaces,
    InstantEstimateData,
    ResidenceSpaces,
    WorkTypes,
} from '../../../templates/InstantEstimate/types'
import { ColumnWrapper } from '../../../molecules/ChoicesContainer'
import Input from '../../../atoms/Input'
import { AreaSizes } from '../AreaSizes'

type TreeType = InstantEstimateData
type Spaces = TreeType['spaces']
export type SpacesProps = {}

type SpacesWithoutOther = Omit<Spaces, 'other'>

const spacesKeys: {
    [k in NonNullable<WorkTypes>]: Record<
        {
            commerce: CommerceSpaces
            residence: ResidenceSpaces
        }[k],
        string
    >
} = {
    commerce: {
        'conference-hall': 'conference-hall',
        bathroom: 'bathroom',
        office: 'office',
    },
    residence: {
        'living-room': 'living-room',
        bathroom: 'bathroom',
        studio: 'studio',
    },
}

export const Spaces = React.memo<SpacesProps>(({}) => {
    const { t } = useTranslation(['estimate'])
    const { update, data } = useTree<TreeType>()
    const tCxt = (item: string) => t('spaces.' + item)
    const workType = data.workType ?? 'residence'
    const targetSpaces = spacesKeys[workType]
    return (
        <React.Fragment>
            <ChoicesBase
                next={AreaSizes.displayName}
                title={tCxt('question')}
                choices={Object.entries(targetSpaces).map(([key, labelKey]) => {
                    return {
                        id: key,
                        title: tCxt(labelKey),
                    }
                })}
                initialSelected={Object.keys(targetSpaces).filter((key) => {
                    return !!data.spaces[key as keyof typeof data.spaces]
                })}
                canGoNext={Object.values(data.spaces).some(Boolean)}
                onSelectedChanged={(selected) => {
                    update((p) => {
                        const spaces: Spaces = {
                            ...p.spaces,
                        }
                        const spacesKeysForWType = spacesKeys[workType]
                        const _keys = Object.keys(spacesKeysForWType) as Array<
                            keyof typeof spacesKeysForWType
                        >
                        _keys.forEach((key) => {
                            spaces[key] = selected.includes(key)
                        })
                        return {
                            ...p,
                            spaces,
                        }
                    })
                }}
                multiChoice={true}
                direction={'column'}
                below={
                    <ColumnWrapper>
                        <Input
                            placeholder={tCxt('other')}
                            value={data.spaces.other ?? ''}
                            onChange={(e) => {
                                const val = e.target.value || null
                                update((p) => ({
                                    ...p,
                                    spaces: {
                                        ...p.spaces,
                                        other: val,
                                    },
                                }))
                            }}
                        />
                    </ColumnWrapper>
                }
            />
        </React.Fragment>
    )
})

Spaces.displayName = 'Spaces'

export default Spaces
