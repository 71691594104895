import styled from 'styled-components'
import Content from '../../../atoms/Content'
import { sBreakpoints } from '../../../constants'
import { P } from '../../../atoms/Text'
import { FCol, FRow } from '../../../atoms/Layout'

export const SContent = styled(Content)`
    flex: 1;
    display: flex;
    overflow: hidden;
    ${sBreakpoints.mobile} {
        overflow: unset;
    }
`
export const FeaturesGrid = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-rows: auto;
`
export const Wrapper = styled(FCol)`
    height: 100vh;
    overflow: hidden;
    ${sBreakpoints.mobile} {
        overflow: unset;
    }
`
export const Info = styled(FCol)`
    flex: 1;
    padding-right: 64px;
    justify-content: center;
    height: 100%;
    padding-bottom: 20%;

    ${sBreakpoints.mobile} {
        text-align: center;
        padding-right: 0;
        align-items: center;
        padding-bottom: 0;
    }
`
export const CollageScroller = styled.div`
    flex: 1;
    overflow: auto;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }

    ${sBreakpoints.mobile} {
        overflow: unset;
        padding-bottom: 70px;
    }
`
export const InfoWrapper = styled(FRow)`
    flex: 1;
    overflow: hidden;
    ${sBreakpoints.mobile} {
        overflow: unset;
        flex-direction: column;
        overflow-x: hidden;
    }
`
export const DArrowWrapper = styled.div`
    flex-shrink: 0;
    padding: 16px;
`
export const SelectDesignText = styled(P)`
    ${sBreakpoints.mobile} {
        color: rgb(114, 122, 135);
    }
`
export const SelectDesignTextWrapper = styled(FRow)`
    align-items: center;
    ${sBreakpoints.mobile} {
        padding-top: 16px;
    }
`
