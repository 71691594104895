import React from 'react'
import { BathroomLayout } from '../../BathroomLayout'
import ChoicesBase from '../../ChoicesBase'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTree } from '../../../molecules/TreeSteps/hook'
import { InstantEstimateData } from '../../../templates/InstantEstimate/types'
import Spaces from '../Spaces'

type TreeType = InstantEstimateData
type TWorkType = TreeType['workType']

const workTypes: Record<NonNullable<TWorkType>, NonNullable<TWorkType>> = {
    commerce: 'commerce',
    residence: 'residence',
}

export type WorkTypeProps = {}

export const WorkType = React.memo<WorkTypeProps>(({}) => {
    const { t } = useTranslation(['estimate'])
    const { update, data } = useTree<TreeType>()
    const tCxt = (item: string) => t('work-type.' + item)
    return (
        <React.Fragment>
            <ChoicesBase
                next={Spaces.displayName}
                title={tCxt('question')}
                choices={[
                    {
                        id: workTypes.residence,
                        title: tCxt('residence'),
                    },
                    {
                        id: workTypes.commerce,
                        title: tCxt('commerce'),
                    },
                ]}
                canGoNext={data.workType !== null}
                onSelectedChanged={([type]) => {
                    update((p) => {
                        return {
                            ...p,
                            workType: type as TWorkType,
                        }
                    })
                }}
                initialSelected={data.workType !== null ? [data.workType] : []}
                multiChoice={false}
                direction={'column'}
            />
        </React.Fragment>
    )
})

WorkType.displayName = 'WorkType'

export default WorkType
