import React from 'react'
import { useSimpleCanGoNext } from '../../ChoicesBase/hooks'
import ChoicesBase from '../../ChoicesBase'
import { BathroomLayout } from '../../BathroomLayout'
import { EstimatorIcon, EstimatorIconProps } from './styled'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTree } from '../../../molecules/TreeSteps/hook'
import { InstantEstimateData, Sizes } from '../../../templates/InstantEstimate/types'
import OwnsBlueprints from '../OwnsBlueprints'
import ChangeSpaceDistribution from '../ChangeSpaceDistribution'

type TreeType = InstantEstimateData

export const AreaSizes = React.memo(() => {
    const { t } = useTranslation(['estimate'])
    const { update, data } = useTree<TreeType>()
    const tCxt = (item: string) => t('area-sizes.' + item)

    return (
        <ChoicesBase
            next={ChangeSpaceDistribution.displayName}
            title={tCxt('title')}
            choices={[
                {
                    id: 'small',
                    title: tCxt('small'),
                    description: '0-50m²',
                },
                {
                    id: 'medium',
                    title: tCxt('medium'),
                    description: '50-100m²',
                },
                {
                    id: 'large',
                    title: tCxt('large'),
                    description: tCxt('bigger-than') + ' 150m²',
                },
            ].map((x) => ({
                ...x,
                icon: (
                    <EstimatorIcon
                        size={x.id as EstimatorIconProps['size']}
                        style={
                            data.areaSize !== x.id
                                ? {
                                      filter: 'contrast(0.1)',
                                  }
                                : {
                                      color: 'white',
                                  }
                        }
                    />
                ),
            }))}
            onSelectedChanged={([_choice]) => {
                const choice = _choice as unknown as Sizes
                switch (choice) {
                    case 'small':
                    case 'medium':
                    case 'large':
                        {
                            update((p) => {
                                return {
                                    ...p,
                                    areaSize: choice,
                                }
                            })
                        }
                        break
                }
            }}
            initialSelected={data.areaSize !== null ? [data.areaSize] : []}
            canGoNext={data.areaSize !== null}
            multiChoice={false}
            direction={'row'}
        />
    )
})

AreaSizes.displayName = 'AreaSizes'
