import React from 'react'
import Done from '../../Done'
import { AreaSizes } from '../AreaSizes'
import { ColumnWrapper } from '../../../molecules/ChoicesContainer'
import Input from '../../../atoms/Input'
import ChoicesBase from '../../ChoicesBase'
import Spaces from '../Spaces'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTree } from '../../../molecules/TreeSteps/hook'
import {
    InstantEstimateData,
    MeetingPlaces,
} from '../../../templates/InstantEstimate/types'
import UserInfo from '../UserInfo'

type TreeType = InstantEstimateData

export type SchedulePlaceProps = {}

const schedulePlaces: MeetingPlaces[] = ['office', 'place']

export const SchedulePlace = React.memo<SchedulePlaceProps>(({}) => {
    const { t } = useTranslation(['estimate'])
    const { update, data } = useTree<TreeType>()
    const tCxt = (item: string) => t('schedule-place.' + item)
    return (
        <React.Fragment>
            <ChoicesBase
                next={UserInfo.displayName}
                title={tCxt('title')}
                choices={schedulePlaces.map((sp) => {
                    return {
                        id: sp,
                        title: tCxt(sp),
                    }
                })}
                initialSelected={data.meetingPlace !== null ? [data.meetingPlace] : []}
                canGoNext={Object.values(data.spaces).some(Boolean)}
                onSelectedChanged={([_choice]) => {
                    const choice = _choice as MeetingPlaces
                    switch (choice) {
                        case 'office':
                        case 'place':
                            {
                                update((p) => {
                                    return {
                                        ...p,
                                        meetingPlace: choice,
                                    }
                                })
                            }
                            break
                    }
                }}
                multiChoice={false}
                direction={'column'}
            />
        </React.Fragment>
    )
})

SchedulePlace.displayName = 'SchedulePlace'

export default SchedulePlace
