import React from 'react'
import { InstantEstimateData } from '../../../templates/InstantEstimate/types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTree } from '../../../molecules/TreeSteps/hook'
import ChoicesBase from '../../ChoicesBase'
import EstimatedBudget from '../EstimatedBudget'

type TreeType = InstantEstimateData

export type OwnsBlueprintsProps = {}

export const OwnsBlueprints = React.memo<OwnsBlueprintsProps>(({}) => {
    const { t } = useTranslation(['estimate'])
    const { update, data } = useTree<TreeType>()
    const tCxt = (item: string) => t('owns-blueprints.' + item)
    return (
        <React.Fragment>
            <ChoicesBase
                next={EstimatedBudget.displayName}
                title={tCxt('title')}
                choices={[
                    {
                        id: 'yes',
                        title: tCxt('yes'),
                    },
                    {
                        id: 'no',
                        title: tCxt('no'),
                    },
                ]}
                onSelectedChanged={([_choice]) => {
                    update((p) => {
                        return {
                            ...p,
                            ownsBlueprints: _choice === 'yes',
                        }
                    })
                }}
                initialSelected={
                    data.ownsBlueprints !== null
                        ? [data.ownsBlueprints ? 'yes' : 'no']
                        : []
                }
                canGoNext={data.ownsBlueprints !== null}
                multiChoice={false}
                direction={'column'}
            />
        </React.Fragment>
    )
})

OwnsBlueprints.displayName = 'OwnsBlueprints'

export default OwnsBlueprints
