import React from 'react'
import { useSimpleCanGoNext } from '../../ChoicesBase/hooks'
import ChoicesBase from '../../ChoicesBase'
import UserInfo from '../UserInfo'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTree } from '../../../molecules/TreeSteps/hook'
import {
    DeliveryTimeType,
    InstantEstimateData,
} from '../../../templates/InstantEstimate/types'
import SchedulePlace from '../SchedulePlace'
type TreeType = InstantEstimateData

const choices: DeliveryTimeType[] = [
    'asap',
    '2-3-months',
    '4-6-months',
    '6-12-months',
    'not-sure-yet',
]

export const DeliveryTime = React.memo(() => {
    const { t } = useTranslation(['estimate'])
    const { update, data } = useTree<TreeType>()
    const tCxt = (item: string) => t('delivery-time.' + item)
    return (
        <ChoicesBase
            next={SchedulePlace.displayName}
            title={tCxt('title')}
            canGoNext={data.deliveryTime !== null}
            initialSelected={data.deliveryTime !== null ? [data.deliveryTime] : []}
            onSelectedChanged={([_choice]) => {
                const choice = _choice as DeliveryTimeType
                switch (choice) {
                    case 'asap':
                    case '2-3-months':
                    case '4-6-months':
                    case '6-12-months':
                    case 'not-sure-yet':
                        {
                            update((p) => ({
                                ...p,
                                deliveryTime: choice,
                            }))
                        }
                        break
                }
            }}
            choices={choices.map((id) => ({ id, title: tCxt(id) }))}
            multiChoice={false}
            direction={'column'}
        />
    )
})

DeliveryTime.displayName = 'DeliveryTime'
