import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTree } from '../../../molecules/TreeSteps/hook'
import ChoicesBase from '../../ChoicesBase'
import { InstantEstimateData } from '../../../templates/InstantEstimate/types'
import OwnsBlueprints from '../OwnsBlueprints'

type TreeType = InstantEstimateData
export type ChangeSpaceDistributionProps = {}

export const ChangeSpaceDistribution = React.memo<ChangeSpaceDistributionProps>(({}) => {
    const { t } = useTranslation(['estimate'])
    const { update, data } = useTree<TreeType>()
    const tCxt = (item: string) => t('change-space-distribution.' + item)
    return (
        <React.Fragment>
            <ChoicesBase
                next={OwnsBlueprints.displayName}
                title={tCxt('title')}
                choices={[
                    {
                        id: 'yes',
                        title: tCxt('yes'),
                    },
                    {
                        id: 'no',
                        title: tCxt('no'),
                    },
                ]}
                onSelectedChanged={([_choice]) => {
                    update((p) => {
                        return {
                            ...p,
                            changeSpaceDistribution: _choice === 'yes',
                        }
                    })
                }}
                initialSelected={
                    data.changeSpaceDistribution !== null
                        ? [data.changeSpaceDistribution ? 'yes' : 'no']
                        : []
                }
                canGoNext={data.changeSpaceDistribution !== null}
                multiChoice={false}
                direction={'column'}
            />
        </React.Fragment>
    )
})

ChangeSpaceDistribution.displayName = 'ChangeSpaceDistribution'

export default ChangeSpaceDistribution
