import React from 'react'
import { PBig } from '../Text'
import * as S from './styled'

export type CheckedTextProps = {}

export const CheckedText: React.FC<CheckedTextProps> = React.memo(({ children }) => {
    return (
        <S.Wrapper>
            <S.SCheckmark />
            <PBig>{children}</PBig>
        </S.Wrapper>
    )
})

CheckedText.displayName = 'CheckedText'

export default CheckedText
