import React from 'react'
import { FCol } from '../../atoms/Layout'
import InstantEstimateTopBar from '../../molecules/InstantEstimateTopBar'
import Content from '../../atoms/Content'
import NextBar from '../../molecules/NextBar'
import { Description, Title, Wrapper } from '../CommonEstimateStep'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTree } from '../../molecules/TreeSteps/hook'
import { InstantEstimateData } from '../../templates/InstantEstimate/types'

type TreeType = InstantEstimateData

export type DoneProps = {}

export const Done: React.FC<DoneProps> = React.memo(({}) => {
    const { t } = useTranslation(['estimate'])
    const { update, data } = useTree<TreeType>()
    const tCxt = (item: string) => t('done.' + item)
    return (
        <React.Fragment>
            <FCol style={{ height: '100vh' }}>
                <InstantEstimateTopBar canBack={false} />
                <Content usePaddingH={true} style={{ paddingBottom: 150, flex: 1 }}>
                    <Wrapper style={{ height: '100%', justifyContent: 'center' }}>
                        <Title>{tCxt('title')}</Title>
                        <Description>{tCxt('description')}</Description>
                    </Wrapper>
                </Content>
            </FCol>
        </React.Fragment>
    )
})

Done.displayName = 'Done'

export default Done
