import React from 'react'
import { LoadingBar } from '../../atoms/LoadingBar'
import TreeSteps from '../../molecules/TreeSteps'
import DesignSelect from '../../organisms/EstimateSteps/DesignSelect'
import { TreeNode } from '../../molecules/TreeSteps/context'
import { InstantEstimateData } from './types'
import { node } from '../../molecules/TreeSteps/utils'
import { getProgressFromPath } from './utils'
import { AreaSizes } from '../../organisms/EstimateSteps/AreaSizes'
import { DeliveryTime } from '../../organisms/EstimateSteps/DeliveryTime'
import UserInfo from '../../organisms/EstimateSteps/UserInfo'
import Done from '../../organisms/Done'
import WorkType from '../../organisms/EstimateSteps/WorkType'
import Spaces from '../../organisms/EstimateSteps/Spaces'
import OwnsBlueprints from '../../organisms/EstimateSteps/OwnsBlueprints'
import ChangeSpaceDistribution from '../../organisms/EstimateSteps/ChangeSpaceDistribution'
import EstimatedBudget from '../../organisms/EstimateSteps/EstimatedBudget'
import SchedulePlace from '../../organisms/EstimateSteps/SchedulePlace'

export type InstantEstimateProps = {}

const steps: TreeNode = node(
    WorkType,
    node(
        Spaces,
        node(
            AreaSizes,
            node(
                ChangeSpaceDistribution,
                node(
                    OwnsBlueprints,
                    node(
                        EstimatedBudget,
                        node(
                            DesignSelect,
                            node(
                                DeliveryTime,
                                node(SchedulePlace, node(UserInfo, node(Done))),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    ),
)

export const InstantEstimate: React.FC<InstantEstimateProps> = React.memo(({}) => {
    const [progress, setProgress] = React.useState(0)
    return (
        <React.Fragment>
            <LoadingBar progress={progress} />
            <TreeSteps<InstantEstimateData>
                initialData={{
                    workType: null,
                    spaces: {
                        bathroom: false,
                        'conference-hall': false,
                        'living-room': false,
                        office: false,
                        studio: false,
                        other: null,
                    },
                    areaSize: null,
                    changeSpaceDistribution: null,
                    ownsBlueprints: null,
                    estimatedBudget: null,
                    selectedDesigns: [],
                    deliveryTime: null,
                    client: {},
                    meetingAddress: null,
                    meetingDate: null,
                    meetingPlace: null,
                    sendInvite: false,
                }}
                tree={steps}
                onPathChange={(newPath) => {
                    setProgress(getProgressFromPath(newPath))
                }}
            />
        </React.Fragment>
    )
})

InstantEstimate.displayName = 'InstantEstimate'

export default InstantEstimate
