import React from 'react'
import { TreeNode } from './context'

export const node = (component: React.FC, ...children: TreeNode[]): TreeNode => {
    const _children: TreeNode['children'] = {}
    for (const child of children) {
        _children[String(child.component?.displayName)] = child
    }
    return {
        component,
        children: _children,
    }
}
