import { FRow } from '../Layout'
import styled from 'styled-components'
import RoundedCheckmark from '../Icons/RoundedCheckmark'

export const Wrapper = styled(FRow)`
    align-items: baseline;
`
export const SCheckmark = styled(RoundedCheckmark)`
    margin: 5px 10px 5px 5px;
`
