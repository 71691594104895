import React from 'react'
import NextBar from '../../../molecules/NextBar'
import Collage from '../../../molecules/Collage'
import { H2 } from '../../../atoms/Text'
import CheckedText from '../../../atoms/CheckedText'
import DoubleArrow from '../../../atoms/Icons/DoubleArrow'
import InstantEstimateTopBar from '../../../molecules/InstantEstimateTopBar'
import { AreaSizes } from '../AreaSizes'
import * as S from './styled'
import {
    CollageScroller,
    DArrowWrapper,
    FeaturesGrid,
    Info,
    InfoWrapper,
    SelectDesignText,
    SelectDesignTextWrapper,
    Wrapper,
} from './styled'
import { useRenderBreakpoints } from '../../../utils/hooks/render'
import { gallery } from '../../../providers/GalleryProvider'
import { useTree } from '../../../molecules/TreeSteps/hook'
import { InstantEstimateData } from '../../../templates/InstantEstimate/types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { DeliveryTime } from '../DeliveryTime'

const designs = gallery.map((g, i) => {
    return {
        image: g.src,
        id: `${g.src}?${new URLSearchParams({
            projectName: g.projectName,
            imageName: g.imageName,
        }).toString()}`,
    }
})
type TreeType = InstantEstimateData

export type DesignSelectProps = {}

export const DesignSelect: React.FC<DesignSelectProps> = React.memo(({}) => {
    const { update, data, next } = useTree<TreeType>()
    const { t } = useTranslation(['estimate'])
    const tCxt = (item: string) => t('design-select.' + item)
    const { onMobile, onTablet } = useRenderBreakpoints()
    return (
        <Wrapper>
            <InstantEstimateTopBar />
            <S.SContent usePaddingH={true}>
                <InfoWrapper>
                    <Info>
                        <H2>{tCxt('title')}</H2>
                        <FeaturesGrid>
                            <CheckedText>{tCxt('design')}</CheckedText>
                            <CheckedText>{tCxt('construction')}</CheckedText>
                            <CheckedText>{tCxt('materials')}</CheckedText>
                            <CheckedText>{tCxt('support')}</CheckedText>
                        </FeaturesGrid>
                        <SelectDesignTextWrapper>
                            {onMobile(() => (
                                <DArrowWrapper>
                                    <DoubleArrow direction={'down'} />
                                </DArrowWrapper>
                            ))}
                            <SelectDesignText>{tCxt('description')}</SelectDesignText>
                            <DArrowWrapper>
                                {onMobile(
                                    ({ matches: mobile }) => (
                                        <DoubleArrow
                                            direction={mobile ? 'down' : 'right'}
                                        />
                                    ),
                                    {
                                        mode: 'handle-both',
                                    },
                                )}
                            </DArrowWrapper>
                        </SelectDesignTextWrapper>
                    </Info>

                    <CollageScroller>
                        {onTablet(
                            ({ matches: tablet }) => (
                                <Collage
                                    items={designs}
                                    nColumns={tablet ? 2 : 3}
                                    selected={data.selectedDesigns}
                                    onClick={(s) => {
                                        update((p) => {
                                            return {
                                                ...p,
                                                selectedDesigns:
                                                    p.selectedDesigns.includes(s.id)
                                                        ? p.selectedDesigns.filter(
                                                              (x) => x !== s.id,
                                                          )
                                                        : [...p.selectedDesigns, s.id],
                                            }
                                        })
                                    }}
                                />
                            ),
                            { mode: 'handle-both' },
                        )}
                    </CollageScroller>
                </InfoWrapper>
                <NextBar
                    onNext={() => {
                        next(DeliveryTime.displayName ?? '')
                    }}
                />
            </S.SContent>
        </Wrapper>
    )
})

DesignSelect.displayName = 'DesignSelect'

export default DesignSelect
