import React from 'react'
import CommonEstimateStep from '../../CommonEstimateStep'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTree } from '../../../molecules/TreeSteps/hook'
import { InstantEstimateData } from '../../../templates/InstantEstimate/types'
import Input from '../../../atoms/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import DesignSelect from '../DesignSelect'

type TreeType = InstantEstimateData

export type EstimatedBudgetProps = {}

export const EstimatedBudget = React.memo<EstimatedBudgetProps>(({}) => {
    const { t } = useTranslation(['estimate'])
    const { update, data } = useTree<TreeType>()
    const tCxt = (item: string) => t('estimated-budget.' + item)
    return (
        <React.Fragment>
            <CommonEstimateStep
                title={tCxt('title')}
                canGoNext={!!data.estimatedBudget}
                next={DesignSelect.displayName}
            >
                <Input
                    value={data.estimatedBudget}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position={'start'}>$</InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        const val = e.target.value
                        update((p) => ({
                            ...p,
                            estimatedBudget: val.replace(/\D*/g, ''),
                        }))
                    }}
                />
            </CommonEstimateStep>
        </React.Fragment>
    )
})

EstimatedBudget.displayName = 'EstimatedBudget'

export default EstimatedBudget
