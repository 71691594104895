import React from 'react'
import { range } from '../../../utils'

export type EstimatorIconProps = {
    size?: 'small' | 'medium' | 'large'
    className?: string
    style?: React.CSSProperties
}

const sqrtAmountOfBoxesBySize = {
    small: 1,
    medium: 2,
    large: 3,
}
export const EstimatorIcon: React.FC<EstimatorIconProps> = ({
    size = 'small',
    className,
    style,
}) => {
    const sqrtAmountOfBoxes = sqrtAmountOfBoxesBySize[size]
    return (
        <svg
            className={className}
            width={48}
            height={48}
            viewBox='0 0 48 48'
            version='1.1'
            style={{ ...style }}
        >
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g transform='translate(-386.000000, -524.000000)' fill='#FFFFFF'>
                    <g transform='translate(386.000000, 524.000000)'>
                        {range(3).map((i) => {
                            return range(3).map((j) => {
                                const opacity =
                                    3 - i <= sqrtAmountOfBoxes && j < sqrtAmountOfBoxes
                                        ? 1
                                        : 0.3
                                return (
                                    <rect
                                        key={i * 3 + j}
                                        opacity={opacity}
                                        x={j * 17}
                                        y={i * 17}
                                        width={14}
                                        height={14}
                                        fill={'currentColor'}
                                        rx={2}
                                    />
                                )
                            })
                        })}
                    </g>
                </g>
            </g>
        </svg>
    )
}
