import React from 'react'
import InstantEstimate from '../templates/InstantEstimate'
import { graphql } from 'gatsby'

export type EstimateProps = {}

export const Estimate = React.memo<EstimateProps>(({}) => {
    return (
        <main>
            <title>Instant Estimate</title>
            <InstantEstimate />
        </main>
    )
})

Estimate.displayName = 'Estimate'

export default Estimate

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
