import React from 'react'
import CommonEstimateStep from '../../CommonEstimateStep'
import styled from 'styled-components'
import { FCol, FRow } from '../../../atoms/Layout'
import Input from '../../../atoms/Input'
import { InfoSmall } from '../../../atoms/Text'
import Lock from '../../../atoms/Icons/Lock'
import { columnGap, rowGap } from '../../../utils'
import Done from '../../Done'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { useTree } from '../../../molecules/TreeSteps/hook'
import { InstantEstimateData } from '../../../templates/InstantEstimate/types'
import { TextFieldProps } from '@material-ui/core'
import GoogleCalendarCheckbox from '../../../molecules/GoogleCalendarCheckbox'
import axios from 'axios'

const Wrapper = styled(FCol)`
    max-width: 560px;
    width: 100%;
    ${rowGap(16)}
    margin-top: 24px;
`

const InfoWrapper = styled(FRow)`
    align-items: center;
    justify-content: center;
    width: 100%;
    ${columnGap(8)}
    padding-top: 16px;
`
type TreeType = InstantEstimateData

export type UserInfoProps = {}

export const UserInfo: React.FC<UserInfoProps> = React.memo(({}) => {
    const { t } = useTranslation(['estimate'])
    const { language } = useI18next()
    const [loading, setLoading] = React.useState(false)

    const { update, data } = useTree<TreeType>()
    const tCxt = (item: string) => t('user-info.' + item)

    React.useLayoutEffect(() => {
        update((p) => ({
            ...p,
            sendInvite: false,
        }))
    }, [])

    type DataKeys = keyof Pick<TreeType, 'meetingAddress' | 'meetingDate'>
    const dataProps = (
        key: DataKeys,
    ): Pick<TextFieldProps, 'value' | 'onChange' | 'placeholder'> => {
        return {
            placeholder: tCxt(key),
            value: data[key] ?? '',
            onChange: (e) => {
                const val = e.target.value
                update((p) => ({
                    ...p,
                    [key]: val,
                }))
            },
        }
    }
    const clientProps = (
        key: keyof TreeType['client'],
    ): Pick<TextFieldProps, 'value' | 'onChange' | 'placeholder'> => {
        return {
            placeholder: tCxt(key),
            value: data.client[key] ?? '',
            onChange: (e) => {
                const val = e.target.value
                update((p) => ({
                    ...p,
                    client: {
                        ...p.client,
                        [key]: key === 'phone' ? val.replace(/\D/g, '') : val,
                    },
                }))
            },
        }
    }
    const canGoNext = (() => {
        const keys: Array<DataKeys> = ['meetingDate']
        if (data.meetingPlace === 'place') keys.push('meetingAddress')
        const clientKeys: Array<keyof TreeType['client']> = ['email', 'fullName', 'phone']
        return (
            keys.every((x) => {
                return !!data[x]
            }) &&
            clientKeys.every((x) => {
                return !!data.client[x]
            })
        )
    })()
    return (
        <CommonEstimateStep
            next={Done.displayName}
            loading={loading}
            onNext={(next) => {
                setLoading(true)
                axios
                    .post(
                        process.env.FUNCTIONS_BASE_URL + '/sendEstimate',
                        JSON.stringify({
                            data,
                            lang: language,
                        }),
                        { headers: { 'Content-Type': 'application/json' } },
                    )
                    .then((re) => {
                        if (re.status === 200) {
                            next()
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }}
            title={tCxt('title')}
            canGoNext={canGoNext}
        >
            <Wrapper>
                <Input {...clientProps('fullName')} />
                <Input {...clientProps('email')} />
                <Input {...clientProps('phone')} />
                {data.meetingPlace === 'place' && (
                    <Input
                        {...dataProps('meetingAddress')}
                        placeholder={tCxt('meetingAddress')}
                        value={data.meetingAddress ?? ''}
                        onChange={(e) => {
                            const val = e.target.value
                            update((p) => ({
                                ...p,
                                meetingAddress: val,
                            }))
                        }}
                    />
                )}
                <Input
                    label={tCxt('meetingDate')}
                    type='datetime-local'
                    {...dataProps('meetingDate')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {data.meetingPlace === 'place' && (
                    <GoogleCalendarCheckbox
                        checked={data.sendInvite}
                        onChange={(checked) => {
                            update((p) => ({
                                ...p,
                                sendInvite: checked,
                            }))
                        }}
                    />
                )}
                <InfoWrapper>
                    <div style={{ flexShrink: 0 }}>
                        <Lock />
                    </div>
                    <InfoSmall>{tCxt('secure-info')}</InfoSmall>
                </InfoWrapper>
            </Wrapper>
        </CommonEstimateStep>
    )
})

UserInfo.displayName = 'UserInfo'

export default UserInfo
