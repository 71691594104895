import React from 'react'
import ChoicesContainer, { ChoicesContainerProps } from '../../molecules/ChoicesContainer'
import { FCol } from '../../atoms/Layout'
import styled from 'styled-components'
import CommonEstimateStep, { CommonEstimateStepProps } from '../CommonEstimateStep'

export type ChoicesBaseProps = {
    onSelectedChanged?(selected: string[]): void
    initialSelected?: string[]
    below?: React.ReactNode
} & CommonEstimateStepProps &
    Pick<ChoicesContainerProps, 'multiChoice' | 'direction' | 'choices'>

const ChoicesWrapper = styled(FCol)`
    width: 100%;
    max-width: 560px;
`
const MultiChoiceWrapper = styled(ChoicesWrapper)`
    max-width: unset;
`

export const ChoicesBase: React.FC<ChoicesBaseProps> = React.memo(
    ({
        title,
        description,
        multiChoice,
        direction,
        choices,
        next,
        canGoNext,
        onSelectedChanged,
        below,
        initialSelected = [],
    }) => {
        const [selected, setSelected] = React.useState<string[]>(initialSelected)
        const CWrapper = choices.find((x) => !!x.icon)
            ? MultiChoiceWrapper
            : ChoicesWrapper
        return (
            <CommonEstimateStep
                title={title}
                description={description}
                canGoNext={canGoNext}
                next={next}
            >
                <CWrapper>
                    <ChoicesContainer
                        choices={choices}
                        selected={selected}
                        onClick={(s) => {
                            setSelected((p) => {
                                if (multiChoice) {
                                    return p.includes(s)
                                        ? p.filter((x) => x !== s)
                                        : [...p, s]
                                }
                                return [s]
                            })
                            setSelected((p) => {
                                onSelectedChanged?.(p)
                                return p
                            })
                        }}
                        multiChoice={multiChoice}
                        direction={direction}
                    />
                    {below}
                </CWrapper>
            </CommonEstimateStep>
        )
    },
)

ChoicesBase.displayName = 'ChoicesBase'

export default ChoicesBase
